.dash-card-title {
    font-weight: bolder;
}

.dash-no-refills-alert {
    background: #fab8eb;
}

.dash-fulfill-btn, .dash-fulfill-btn:hover {
    background: #f014be;
    color: white;
}

.search-btn-outline {
    border: 1px solid #f014be;
}

.refill-requests-title {
    text-align: center;
    margin-top: 1em;
    font-size: 25px;
    font-weight: bolder;
    font-family: 'Roboto Slab', serif;
}

.card-container .card {
    transition: all 0.3s ease; 
  }

.card-container2 .card {
    transition: all 0.3s ease; 
  }

.schedule-appt-title {
    font-weight: bolder;
    font-family: 'Roboto Slab', serif;
    font-size: 25px;
}

.day-passed,
.time-passed {
    background-color: #f0f0f0; 
    color: #6c757d; 
    pointer-events: none; 
    opacity: 0.5; 
}

.confirm-text {
    font-weight: bolder;
}

.navigate-btn, .navigate-btn:hover {
    background: #f014be;
    color: white;
}

.schedule-consult-modal {
    z-index: 4000;
}

.confirm-appt-modal {
    z-index: 5000;
}

.close-schedule, .close-schedule:hover {
    background: #fab8eb;
    color: black;
}

.dashboard-card-container {
    height: 630px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.dashboard-paginatio-btn {
    background: #f014be;
    color: white;
}

.card-container2, .card-container {
    min-height: 400px;
}

.view-record-btn, .patientDetailsClose {
    background: #fab8eb;
    color: black;
}

.patient-details {
    font-weight: bolder;
    margin-bottom: 1em;
}
  
  