.navbar {
    z-index: 1000;
    background: #fab8eb;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.navbar-collapse {
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 0;
  }

.med-icon {
    font-size: 35px;
    color: #f014be;
}

.med-icon-toggler {
    border: none;
}

.med-icon-toggler:hover {
    border: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 700px) {
  .navbar-collapse {
      max-height: none !important; 
      overflow: visible !important;
  }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); 
    }
    100% {
      transform: scale(1);
    }
  }
  
.pulse {
    animation: pulse 0.8s ease-in-out;
    color: #f014be;
  }

.spin {
    animation: spin 0.5s ease-in-out;
    color: #f014be;
}

  