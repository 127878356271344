.values-container {
    padding: 3em 8em 5em 8em;
    /* background: linear-gradient(to bottom, white, white, #fab8eb); */
}

.values-title {
    font-family: 'Roboto Slab', serif;
}

.values-card {
    border-right: none;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.values-card-title {
    font-family: 'Roboto Slab', serif;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 200px) { 
    .values-container {
        /* background: linear-gradient(to bottom, white, white, #fab8eb); */
        position: relative;
        padding: 2em;
    }

    .values-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-image: url('https://i.ibb.co/GF99Pvv/carrcarebanner1-1.png');  */
        background-size: 200px;
        background-repeat: repeat;
        opacity: 0.3;
        z-index: 1; 
    }

    .values-card {
        background: white;
    }
 }

 @media (min-width: 700px){
    .values-container {
        padding: 3em 8em 5em 8em;
    }
 }