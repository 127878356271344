html, body {
  max-width: 100%;
  /* overflow-x: hidden; */
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flexible-content {
  flex: 1;
  overflow-x: hidden;
}

