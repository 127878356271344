.slider-container {
    background: #fab8eb;
    padding: 2em;
}

.carrcarelogo-reviews {
    height: 50px;
    width: auto;
    text-align: center;
}

.reviews-card-text {
    font-size: 14px;
    font-weight: bolder;
}

.review-card {
    /* height: 100%;
    width: auto; */
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: scroll;
}

.review-card-margin {
    margin-right: 10px;
}

.reviews-card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1em 0 1em;
}

.reviewer {
    font-family: 'Roboto Slab', serif;
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
}

.slider-container .slick-slide {
    display: flex;
    justify-content: center;
  }

  .reviews-title {
    font-family: 'Roboto Slab', serif;
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 1em;
  }

  .review-img {
    height: 100px;
    width: auto;
    border-radius: 20%;
  }
  