.quest-button {
    background: #f014be;
    color: white;
}

.initial-quest-text {
    font-size: 25px;
    font-family: 'Roboto Slab', serif;
}

.initial-quest-title {
    font-size: 35px;
    font-family: 'Roboto Slab', serif;
}

.initial-quest-title1 {
    font-size: 35px;
    font-family: 'Roboto Slab', serif;
}

.quest-options-row {
    margin: 0 8em 0 8em;
}

.quest-container {
    height: 100%;
}

@media (min-width: 200px) {
    .initial-quest-text {
        font-size: 15px;
    }

    .quest-options-row {
        margin: 0;
    }
}

@media (min-width: 700px) {
    .initial-quest-text {
        font-size: 25px;
    }
}