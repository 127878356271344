.spinner-title {
    margin-bottom: 40px;
    font: 2em 'Roboto Slab', serif;
  }
  
  #loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #f014be;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }